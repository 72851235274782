.teams-screen {
  padding: 0 !important;
}

.teams-screen__tools-row {
  margin-top: 2px;
  padding: 0 32px;
  background-color: var(--color-white);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(81, 63, 85, 0.2);
  z-index: 1;
  min-height: 64px;
  display: flex;
  align-items: center;
}

.teams-screen__tools-row > div {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.teams-screen__content {
  padding: 32px;
  display: flex;
  justify-content: center;
}

.teams-screen__card {
  max-width: 960px;
}

.teams-screen__list-container {
  height: auto;
}

.teams-screen__header {
  display: flex;
  align-items: center;
  height: 48px;
  border-bottom: solid 1px var(--color-gray-light);
}

.teams-screen__header-column--first {
  flex: 1;
  min-width: 180px;
  text-align: left;
  white-space: nowrap;
  margin-left: 16px;
}

.teams-screen__header-column {
  width: 120px;
  min-width: 60px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.teams-screen__header-column--last {
  width: 60px;
  min-width: 60px;
  text-align: left;
  white-space: nowrap;
  margin-right: 16px;
}
