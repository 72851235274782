.virtualized-list {
  width: 100%;
  height: 100%;
  outline: 0 none;
}

.virtualized-list__row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.virtualized-list__placeholder {
  text-align: center;
  color: var(--color-gray-darker);
  padding: 32px;
}
