.campaign-detail-screen {
  padding: 0px 0px !important;
}

.campaign-detail-dashboard {
  min-height: 210px;
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  border-radius: 4px;
}

.campaign-detail__header {
  display: flex;
  justify-content: space-between;
  padding: 32px 32px 0px 32px;
  border-top: solid 2px var(--color-background);
}

.campaign-detail__actions {
  display: flex;
}

.campaign-detail-dashboard__graph {
  border-radius: 4px;
  border: solid 1px var(--color-background-dark);
}

.campaign-detail-dashboard__kpis {
  border-radius: 4px;
  border: solid 1px var(--color-background-dark);
  height: 222px;
  display: flex;
  padding: 16px;
  box-sizing: border-box;
}

.campaign-detail-dashboard__inno {
  border-radius: 4px;
  border: solid 1px var(--color-background-dark);
  height: 160px;
  display: flex;
  padding: 16px;
  box-sizing: border-box;
}

.campaign-detail-dashboard__card {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  fill: var(--color-black);
  border-right: solid 1px var(--color-background);
}

.campaign-detail-dashboard__inno__card {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  fill: var(--color-inno);
}

.campaign-detail-dashboard__kpis__card {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.campaign-detail-dashboard__kpis__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 64px;
  fill: var(--color-black);
}

.campaign-detail-dashboard__graphs {
  display: flex;
}

.campaign-detail-dashboard__left {
  flex: 1;
  margin-left: 16px;
}

.campaign-detail-dashboard__right {
  flex: 1;
  margin-right: 16px;
}

.campaign-detail-dashboard__graph-container {
  margin: 16px;
}

.campaign-detail-dashboard__funnel__label--small {
  font-size: var(--fontsize-small);
}

.campaign-detail-dashboard__funnel__label--big {
  font-size: var(--fontsize-large);
  font-weight: bold;
}

.campaign-detail__users {
  flex: 1;
  width: 320px;
}

.campaign-detail__ideas {
  flex: 3;
  margin-right: 16px;
}

.campaign-detail__user-list {
  list-style: none;
  padding: 0px;
  padding-top: 4px;
  margin: 0px;
}
.campaign-detail__user-list-order {
  margin-right: 8px;
  font-weight: bold;
  width: 32px;
  text-align: center;
  border-radius: 16px;
}
.campaign-detail__user-list-order--1 {
  height: 32px;
  line-height: 32px;
  background-color: #f1d678;
}

.campaign-detail__user-list-order--2 {
  height: 32px;
  line-height: 32px;
  background-color: silver;
}
.campaign-detail__user-list-order--3 {
  height: 32px;
  line-height: 32px;
  background-color: burlywood;
}

.campaign-detail__idea {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.campaign-detail__idea-order {
  font-weight: bold;
  width: 32px;
  text-align: center;
  border-radius: 0px 3px 0px 4px;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 32px;
  line-height: 32px;
  background-color: var(--color-white);
}

.campaign-detail__idea-order--1 {
  height: 32px;
  line-height: 32px;
  background-color: #f1d678;
}

.campaign-detail__idea-order--2 {
  height: 32px;
  line-height: 32px;
  background-color: silver;
}
.campaign-detail__idea-order--3 {
  height: 32px;
  line-height: 32px;
  background-color: burlywood;
}

.campaign-detail__user-row {
  display: flex;
  align-items: center;
  font-size: var(--fontsize-small);
  height: 48px;
  border-top: solid 1px var(--color-background);
}

.campaign-detail__ideas-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 200px));
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding: 8px 4px 16px 4px;
}

.campaign-detail__leaderboards {
  display: flex;
  padding-left: 32px;
  padding-right: 32px;
}

.campaign-detail__leaderboard {
  padding: 16px 16px 16px 16px;
  border-radius: 4px;
}

.campaign-detail__leaderboard-tab {
  display: block;
  flex: 1;
}

.campaign-detail__buttons {
  display: flex;
  width: 100%;
}

.campaign-detail__leaderboard-tab__input {
  display: none;
}

.campaign-detail__leaderboard-tab__input
  + label
  .campaign-detail__leaderboard-tab__button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  border-bottom: solid 4px var(--color-background);
  height: 24px;
  transition: border 150ms ease-in-out, fill 150ms ease-in-out;
}

.campaign-detail__leaderboard-tab__input:checked
  + label
  .campaign-detail__leaderboard-tab__button {
  border-bottom: solid 4px var(--color-secondary);
  fill: var(--color-secondary);
}

.campaign-detail__leaderboard-tab__input
  + label
  .campaign-detail__leaderboard-tab__button:hover {
  border-bottom: solid 4px var(--color-secondary);
  fill: var(--color-secondary);
}

.campaign-detail__notification-card {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  cursor: pointer;
}
