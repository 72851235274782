.ingrid-poll {
  display: flex;
}

.ingrid-poll__content {
  display: flex;
  flex-direction: column;
}

.ingrid-poll__header-link {
  text-decoration: none;
  color: var(--color-black);
  margin: 0px;
  padding: 0px;
}

.ingrid-poll__header-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.ingrid-poll__header-dropdown {
  position: absolute;
  height: 24px;
  width: 24px;
}

.ingrid-poll__header-link--draft,
.ingrid-poll__header-link--ready {
  text-decoration: none;
  color: var(--color-black);
  fill: var(--color-black);
}

.ingrid-poll__header-link--running,
.ingrid-poll__header-link--pending-approval {
  text-decoration: none;
  color: var(--color-white);
  fill: var(--color-white);
}

.ingrid-poll__header-link--finished {
  text-decoration: none;
  color: var(--color-gray-darker);
  fill: var(--color-gray-darker);
}

.ingrid-poll__header-link:hover {
  text-decoration: underline;
}

.ingrid-poll__header > span {
  display: block;
}

.ingrid-poll__header-container {
  display: flex;
  align-items: center;
  padding: 12px;
  padding-right: 8px;
  border-radius: 4px 4px 0px 0px;
  border-bottom: solid 1px var(--color-background);
  fill: var(--color-black);
}

.ingrid-poll__header-container--draft {
  background-color: var(--color-alert);
  border-bottom: solid 1px var(--color-background);
  fill: var(--color-black);
}

.ingrid-poll__header-container--ready {
  background-color: var(--color-white);
  border-bottom: solid 1px var(--color-background);
}

.ingrid-poll__header-container--running {
  background-color: var(--color-green);
  border-color: var(--color-green);
  color: var(--color-white);
  fill: var(--color-white);
}

.ingrid-poll__header-container--running .ingrid-poll__header-menu {
  fill: var(--color-white);
}

.ingrid-poll__header-container--pending-approval {
  background-color: var(--color-danger);
  color: var(--color-white);
}

.ingrid-poll__header-container--pending-approval .ingrid-poll__header-menu {
  fill: var(--color-white);
}

.ingrid-poll__header-container--finished {
  background-color: var(--color-gray-lighter);
  border-color: var(--color-gray-lighter);
  color: var(--color-gray-darker);
  fill: var(--color-gray-darker);
}

.ingrid-poll__header-container--draft .ingrid-poll__header-menu {
  fill: var(--color-black);
}

.ingrid-poll__header-container--ready .ingrid-poll__header-menu {
  fill: var(--color-black);
}
.ingrid-poll__header-container--finished .ingrid-poll__header-menu {
  fill: var(--color-gray-darker);
}

.ingrid-poll__header {
  width: 100%;
  padding: 4px 0px;
}

.ingrid-poll__header-menu {
  display: flex;
  align-items: center;
}

.ingrid-poll__header-menu:hover {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}

.ingrid-poll__header-name {
  margin-bottom: 2px;
  margin-right: 8px;
  font-weight: bold;
}

.ingrid-poll__header-date {
  font-size: var(--fontsize-small);
}

.ingrid-poll__row {
  padding: 0px 12px;
  height: 32px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-background);
}

.ingrid-poll__row--bottom {
  padding: 0px 12px;
  height: 100%;
  display: flex;
  border-bottom: none;
  height: 32px;
  padding-bottom: 8px;
  padding-top: 6px;
}

.ingrid-poll__category-name {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.ingrid-poll__category-type {
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 4px;
}

.ingrid-poll__category-users {
  margin-left: 8px;
}

.ingrid-poll__category-count {
  display: flex;
  align-items: center;
  text-align: right;
  margin-left: 12px;
}

.ingrid-poll__category-state {
  display: flex;
  align-items: center;
  width: 100%;
}

.ingrid-poll__category-teams {
  display: flex;
  align-items: center;
}

.ingrid-poll__icon-box--nocircle {
  padding-left: 2px;
  fill: var(--color-black);
}
