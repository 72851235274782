.ingrid-team-row {
  transition: background-color 150ms ease-in-out;
  width: 100%;
  box-sizing: border-box;
  height: 48px;
  display: flex;
  border-bottom: solid 1px var(--color-background);
}

.ingrid-team-row__container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}

.ingrid-team-row__column--left {
  flex: 1;
  margin-left: 16px;
  min-width: 180px;
  display: flex;
  align-items: center;
}

.ingrid-team-row__column {
  width: 120px;
  min-width: 60px;
  text-align: center;
  white-space: nowrap;
}

.ingrid-team-row__column--right {
  text-align: right;
  margin-right: 16px;
  width: 60px;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ingrid-team-row:hover {
  background-color: var(--color-background-dark);
}

.ingrid-team-row__placeholder:before {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 16px;
  background-color: var(--color-gray-light);
  margin: 16px 8px 16px 16px;
}

.ingrid-team-row__placeholder {
  /* nah */
  height: 48px;
  display: flex;
}

.ingrid-team-row__placeholder:after {
  content: "";

  height: 12px;
  width: 160px;
  background-color: var(--color-gray-light);
  border-radius: 4px;
  margin: 20px 0;
}
