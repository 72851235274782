.idea-campaign {
  background-color: var(--color-background);
  border-radius: 4px;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
}

.idea-campaign__name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  width: 100%;
}

.idea-campaign__description {
  font-size: var(--fontsize-small);
  margin-left: 30px;
}

.idea-campaign__description-text {
  border-left: solid 2px var(--color-background-dark);
  margin-bottom: 16px;
  padding-left: 8px;
}

.idea-campaign__order {
  padding-left: 16px;
  margin-right: 16px;
  margin-left: 16px;
  border-left: solid 2px var(--color-background-dark);
}

.idea-campaign__settings-icon {
  background-color: transparent;
  border: none;
  padding: 4px;
  fill: var(--color-gray-light);
}

.idea-campaign__left {
  display: flex;
  align-items: center;
}

.edit-description__input-container {
  padding-right: 16px;
}

.idea-campaign__buttons-container {
  display: flex;
  height: 24px;
  margin-right: 8px;
}

.idea-campaign__dropdown {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-left: 4px;
  transition: background-color 150ms ease-in-out;
}

.idea-campaign__dropdown:hover {
  background-color: var(--color-gray-lighter);
}

.idea-campaign__dropdown__text {
  position: absolute;
  width: 24px;
  height: 24px;
  fill: var(--color-black);
}

.idea-campaign__dropdown-container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.edit-description__buttons {
  display: flex;
  justify-content: flex-end;
  margin-right: 16px;
  margin-top: 8px;
  margin-bottom: 16px;
}

.edit-description__buttons .button {
  width: 140px;
}
