.notifications__timeline__container {
  max-width: 480px;
}

.notifications__headline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}
.notifications__timeline {
  position: relative;
  padding-left: 48px;
  background: linear-gradient(
    90deg,
    transparent,
    45%,
    var(--color-gray-light) 45%,
    55%,
    transparent 55%
  );
  background-size: 32px 100%;
  background-repeat: no-repeat;
  background-position-y: bottom;
  padding-bottom: 8px;
}

.notifications__timeline:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--color-white);
  border: solid 2px transparent;
  background-image: url("../../assets/icons/color/icon-checkbox-completed-big.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
  left: 4px;
  top: calc(50% - 16px);
  box-sizing: border-box;
}

.notifications__timeline--upcoming:before {
  background-color: var(--color-white);
  border: solid 2px var(--color-gray-light);
  background-image: none;
}

.notifications__timeline:first-child {
  background: linear-gradient(
    90deg,
    transparent,
    45%,
    var(--color-gray-light) 45%,
    55%,
    transparent 55%
  );
  background-size: 32px 50%;
  background-repeat: no-repeat;
  background-position-y: bottom;
}

.notifications__timeline:last-child {
  background: linear-gradient(
    90deg,
    transparent,
    45%,
    var(--color-gray-light) 45%,
    55%,
    transparent 55%
  );
  background-size: 32px 50%;
  background-repeat: no-repeat;
  background-position-y: top;
}
