.template-wizard-category {
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 24px;
  max-width: 480px;
  width: 100%;
  box-sizing: border-box;
}

.template-wizard-category--disabled {
  opacity: 0 !important;
}

.template-wizard-category--secondary {
  opacity: 1;
  transition: opacity 150ms ease-in-out;
  margin-left: 16px;
}

.template-wizard-category-examples {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  box-sizing: border-box;
}

.template-wizard-category-examples__idea {
  margin-right: 8px;
  box-sizing: border-box;
  height: 100%;
}

.template-wizard-category__section--inline {
  display: flex;
  margin-bottom: 16px;
}

.template-wizard-category__section {
  margin-bottom: 16px;
}

.template-wizard-category__name {
  flex: 2;
  box-sizing: border-box;
}

.template-wizard-category__type {
  flex: 1;
}

.template-wizard-category__section__enable-checkbox {
  margin-left: 16px;
}
