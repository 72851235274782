.wizard-campaign-target__container {
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
  max-width: 480px;
  margin-bottom: 16px;
}

.wizard-campaign__section {
  max-width: 480px;
}

.wizard-campaign__section--flex {
  display: flex;
}

.wizard-campaign-target__organization {
  width: 100%;
}

.wizard-campaign-target__team {
  width: 100%;
}

.wizard-campaign-target__user {
  width: 100%;
}

.target-type-radio {
  display: flex;
  flex: 1;
  margin-top: 16px;
}

.target-type-radio:last-child {
  margin-right: 0px;
}

.target-type-radio__label {
  display: block;
  flex: 1;
}

.target-type-radio__input {
  display: none;
}

.target-type-radio__input + label .target-type-radio__button {
  background-color: var(--color-white);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 2px transparent;
  height: 160px;
  transition: border 150ms ease-in-out, fill 150ms ease-in-out,
    transform 150ms ease-in-out;
}

.target-type-radio__input:checked + label .target-type-radio__button {
  border: solid 2px var(--color-secondary);
  fill: var(--color-secondary);
  transform: scale(1.05);
}

.target-type-radio__input + label .target-type-radio__button:hover {
  border: solid 2px var(--color-secondary);
  fill: var(--color-secondary);
  transform: scale(1.05);
}
