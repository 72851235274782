/**
 * Using in grids of ideas/campaigns/templates ass create button/link
 *
 * Note: double .ingrid-add-card.ingrid-add-card used to increase total weight of selector
 */

.ingrid-add-card.ingrid-add-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 128px;
  height: 100%;
  border-radius: 8px;
  background-color: var(--color-gray-lighter);
  transition: background-color 150ms ease-in-out;
  text-decoration: none;
  border: none;
}

.ingrid-add-card.ingrid-add-card:hover {
  background-color: var(--color-gray-light);
}
