.campaigns-evaluate {
  margin-bottom: 32px;
}

.campaigns-evaluate__card {
  color: var(--color-white);
  background-color: var(--color-danger);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.campaigns-evaluate__info {
  display: flex;
  align-items: center;
}
