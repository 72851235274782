.current-campaigns {
  margin-top: 16px;
  margin-bottom: 32px;
  display: flex;
}

.current-campaign {
  background-color: var(--color-white);
}

.current-campaign__header {
  display: flex;
  align-items: center;
  height: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin: 16px;
  border-bottom: solid 2px var(--color-background);
}

.current-campaign__header-left {
  display: flex;
  flex: 2;
  align-items: center;
}
.current-campaign__header-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.current-campaign__content {
  display: flex;
}

.current-campaigns--empty {
  margin-top: 16px;
  margin-bottom: 32px;
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.current-campaigns__main-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.current-campaigns__header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
}

.current-campaigns__buttons {
  display: flex;
  width: 100%;
}

.current-campaigns__headline {
  padding-bottom: 0px;
}

.current-campaigns__main-headline {
  width: 100%;
  display: flex;
  align-items: center;
}

.current-campaigns__users {
  flex: 1;
  margin-left: 16px;
}

.current-campaigns__no-leaderboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--fontsize-small);
  border-bottom: solid 1px var(--color-background);
  height: 48px;
}

.current-campaigns__select {
  width: 360px;
  margin-right: 8px;
}

.current-campaigns__button {
  width: 160px;
}

.current-campaigns__ideas {
  background-color: var(--color-white);
  border-radius: 4px;
  margin-right: 16px;
  flex: 2;
  overflow: auto;
}

.current-campaigns__ideas-grid {
  display: grid;
  grid-template-columns: repeat(9, minmax(160px, 200px));
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding: 8px 4px 16px 4px;
}

.current-campaigns__ideas-grid__scroll {
  display: flex;
}

.current-campaigns__link {
  margin-right: 16px;
  text-decoration: none;
  font-size: var(--fontsize-small);
  line-height: 21px;
}

.current-campaigns__notifications {
  background-color: var(--color-white);
  padding: 24px;
  border-radius: 4px;
  flex: 1;
}

.current-campaigns__idea {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.current-campaigns__idea-order {
  font-weight: bold;
  width: 32px;
  text-align: center;
  border-radius: 0px 2px 0px 4px;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 32px;
  line-height: 32px;
  background-color: var(--color-white);
}

.current-campaigns__idea-order--1 {
  height: 32px;
  line-height: 32px;
  background-color: #f1d678;
}

.current-campaigns__idea-order--2 {
  height: 32px;
  line-height: 32px;
  background-color: silver;
}
.current-campaigns__idea-order--3 {
  height: 32px;
  line-height: 32px;
  background-color: burlywood;
}

.current-campaigns__notification-card {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  cursor: pointer;
}
