.organization {
  display: flex;
}

.organization__content {
  display: flex;
  flex-direction: column;
}

.organization--highlight {
  transform: scale(1.02);
  box-shadow: 0px 4px 6px var(--color-secondary),
    0 0 0 2px var(--color-secondary) !important;
}

.organization__row {
  padding: 0px 12px;
  height: 32px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-background);
}

.organization__row--bottom {
  padding: 0px 12px;
  height: 100%;
  display: flex;
  border-bottom: none;
  height: 32px;
  padding-bottom: 8px;
  padding-top: 6px;
}
