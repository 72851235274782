.ingrid-loading {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  fill: var(--color-secondary);
  padding: 64px 0px 64px 0px;
}

.ingrid-loading__icon {
  width: 32px;
  height: 32px;
}

.ingrid-loading--small {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  fill: var(--color-secondary);
  padding: 24px 0px 24px 0px;
}

.ingrid-loading__icon--small {
  width: 24px;
  height: 24px;
}

.ingrid-loading__rotate {
  animation: rotation 4s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
