.login-screen {
  width: 480px;
  margin: 64px auto;
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 32px;
}

.login-screen__form {
  padding: 10%;
}

.login-screen__headline {
  margin-bottom: 16px;
}

.login-screen__form input {
  margin-top: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.login-screen__link {
  text-align: right;
  font-size: var(--fontsize-small);
}

.login-screen__submit {
  margin-top: 16px;
}

.login-screen__error {
  color: var(--color-error);
}

.login-screen__logo {
  fill: var(--color-primary);
}
