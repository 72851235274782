body {
  font-family: "Open Sans";
  margin: 0;
  color: var(--color-black);
  font-size: var(--fontsize-normal);

  /* AppLayout needs this, to adjust header,
  sidebar, main containers */
  max-height: 100vh;
  overflow: hidden;

  background-color: var(--color-background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ingrid-input,
input,
select,
textarea,
button {
  font-family: inherit;
}

a {
  color: var(--color-primary);
}

/* Normal input */
.ingrid-input:not([type="checkbox"]) {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 1px solid var(--color-gray-light);
  background-color: var(--color-white);
  color: var(--color-black);
  font-size: var(--fontsize-normal);
  padding-left: 16px;
  box-sizing: border-box;
}

::placeholder {
  color: var(--color-gray-dark);
}

.ingrid-input.ingrid-input--small {
  height: 38px;
  border-radius: 4px;
  padding-left: 8px;
}

/* Textarea  */
.ingrid-textarea {
  display: block;
  resize: none;
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--color-gray-light);
  background-color: var(--color-white);
  font-size: var(--fontsize-normal);
  color: var(--color-black);
  padding: 8px 0px 0px 8px;
  box-sizing: border-box;
}

[data-title] {
  position: relative;
  z-index: 999;
  font-weight: normal;
}

[data-title]:before,
[data-title]:after {
  content: "";
  transition: 200ms opacity;
  opacity: 0;
}

[data-title]:hover::before {
  content: attr(data-title);
  opacity: 1;
  position: absolute;
  bottom: calc(100% + 8px);
  transform: translate(-50%, 0px);
  display: inline-block;
  padding: 3px 6px;
  border-radius: 4px;
  background: #000;
  color: #fff;
  min-width: 120px;
  max-width: 240px;
  font-size: var(--fontsize-small);
  transition-delay: 500ms;
}
[data-title]:hover::after {
  content: "";
  opacity: 1;
  position: absolute;
  top: -10px;
  left: 4px;
  display: inline-block;
  color: #fff;
  border: 8px solid transparent;
  border-top: 8px solid #000;
  transition-delay: 500ms;
}

[data-title-right] {
  position: relative;
  z-index: 999;
  font-weight: normal;
}

[data-title-right]:before,
[data-title-right]:after {
  content: "";
  transition: 200ms opacity;
  opacity: 0;
}

[data-title-right]:hover::before {
  content: attr(data-title-right);
  opacity: 1;
  position: absolute;
  left: 16px;
  display: inline-block;
  padding: 3px 6px;
  border-radius: 4px;
  background: #000;
  color: #fff;
  min-width: 120px;
  max-width: 240px;
  font-size: var(--fontsize-small);
  transition-delay: 500ms;
}

/* Pořád se mi opakujou marginy, tak jsem k tomu udělal systém */
@import "./helpers.css";

:root {
  --sidebar-width: 240px;
  --header-height: 64px;

  /* UI colors */
  --color-primary: #543191;
  --color-primary-dark: #412572;
  --color-primary-light: #805aa7;
  --color-primary-lighter: #9864c9;
  --color-secondary: #535ac0;
  --color-secondary-light: #6369c5;
  --color-black: #505661;
  --color-gray-lighter: #cdd6e9;
  --color-gray-light: #b4c4e2;
  --color-gray-dark: #8691a3;
  --color-gray-darker: #6c7481;
  --color-white: #ffffff;
  --color-background: #edf1ff;
  --color-background-dark: #d8e0f7;
  --color-shadow: rgba(66, 71, 111, 0.2);
  --color-error: #c9263c;
  --color-error-light: #e23a50;
  --color-danger: #ff5c00;
  --color-danger-light: #ff823a;
  --color-alert: #ffc700;
  --color-green: #85c441;
  --color-inno: #41cbcb;
  /* Chart colors */
  --color-chart-1: #73b9e2;
  --color-chart-2: #bed66a;
  --color-chart-3: #f2c473;
  --color-chart-4: #9ad2fb;
  --color-chart-5: #cf9cc8;
  --color-chart-6: #edd162;
  --color-chart-7: #9ab8c4;
  --color-chart-8: #cbc5ab;
  --color-chart-9: #6db8c8;
  --color-chart-10: #dbd960;
  --color-chart-11: #9aabf5;
  /* Font sizes */
  --fontsize-normal: 14px;
  --fontsize-small: 12px;
  --fontsize-tiny: 10px;
  --fontsize-large: 21px;
  --fontsize-mega: 28px;
}
