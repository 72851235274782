.modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal--open {
  opacity: 1;
  visibility: visible;
}

.modal__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
}

.modal__content {
  display: flex;
  max-width: 90vw;
  max-height: 90vh;
  pointer-events: auto;

  box-sizing: border-box;

  background: #fff;

  box-shadow: 0px 2px 4px rgba(81, 63, 85, 0.2);
  border-radius: 5px;
  z-index: 1;

  position: relative;
}

.modal__content {
  overflow-x: auto; /* scroll */
}

.modal__content-outside {
  position: relative;
}
/* Close button :trollface: */
.modal__close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0px;
  right: -48px;
  border-radius: 6px;
  background-color: var(--color-white);
  transition: background-color 0.2s ease-in-out, fill 0.2s ease-in-out;
  fill: var(--color-black);
}

.modal__close:hover {
  background-color: var(--color-gray-light);
  fill: var(--color-white);
}
