.ingrid-tag {
  background-color: var(--color-primary-lighter);
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  height: 20px;
  cursor: pointer;
  white-space: nowrap;
}

.ingrid-tag:hover {
  background-color: var(--color-danger);
}
