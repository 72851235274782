.org-remove-screen {
  padding: 8px 16px 16px 16px;
  min-height: 360px;
  min-width: 320px;
  display: flex;
  flex-direction: column;
}

.org-remove-screen__form {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.org-remove-screen__form > div {
  width: 100%;
}

.org-remove-screen__form {
  margin: 8px 0;
}
