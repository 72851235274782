.card {
  box-sizing: border-box;
  background-color: var(--color-white);
  box-shadow: 0px 2px 4px var(--color-shadow);
  border-radius: 4px;
  width: 100%;
  padding: 16px;
}

.card--fullwidth {
  padding: 0px;
}

.card--small {
  padding: 12px;
}
