.campaign-detail__dashboard {
  min-height: 210px;
  background-color: var(--color-white);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 16px;
  margin-bottom: 32px;
  border-radius: 4px;
  padding: 32px;
  border-top: solid 2px var(--color-background-dark);
}
