.image-upload__input[type="file"] {
  display: none;
}

/* later for on focus with keyboard only */
.image-upload__input:focus + label {
  border: 1px solid red;
}

.image-upload__button {
  box-sizing: border-box;
  background-color: var(--color-gray-lighter);
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  border-radius: 4px;
  position: relative;
  transition: background-color 150ms ease-in-out;
}

.image-upload__button:hover {
  background-color: var(--color-gray-light);
}

.image-upload__preview img {
  border-radius: 4px;
}

.image-upload__hover-text {
  position: absolute;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  font-weight: bold;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-upload__hover-text:hover {
  position: absolute;
  opacity: 1;
}

.image-upload__preview--default {
  height: 100px;
}

.image-upload__image {
  width: 100%;
  height: auto;
}
