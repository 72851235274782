.ingrid-target-select__card {
  background-color: var(--color-white);
  border-radius: 4px;
  margin-top: 8px;
  padding: 8px;
  min-width: 260px;
  box-sizing: border-box;
}

.ingrid-target-select__card--noselection {
  background-color: transparent;
  border-radius: 4px;
  margin-top: 4px;
  padding: 8px;
  min-width: 260px;
  box-sizing: border-box;
}

.ingrid-target-select__list {
  padding: 0px;
  list-style: none;

  margin: 0px;
}

.ingrid-target-select__list-item {
  list-style: none;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: solid 1px var(--color-background);
}

.ingrid-target-select__list-item:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.ingrid-target-select__user {
  display: flex;
  width: 100%;
  align-items: center;
}

.ingrid-target-select__text {
  margin-left: 8px;
  width: 100%;
}

.ingrid-target-select__delete {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: var(--color-danger);
  border-radius: 4px;
}

.ingrid-target-select__delete:hover {
  width: 20px;
  height: 20px;
  fill: var(--color-white);
  background-color: var(--color-danger);
}
