.sidebar {
  display: flex;
  flex-direction: column;
  background-color: var(--color-primary);
  height: 100%;
}

.sidebar__navbar {
  flex: 1;
}

.sidebar__navbar ul {
  padding-left: 0px;
}

.sidebar__navbar ul li {
  list-style-type: none;
  font-weight: bold;
}

.sidebar__iconFrame {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  height: 48px;
  width: 48px;
}

.sidebar__navbar ul img {
  filter: brightness(0) invert(1);
}

.sidebar__navbar ul li a {
  transition: background-color 150ms ease-in;
  display: block;
  padding-left: 16px;
  text-decoration: none;
  color: var(--color-white);
  line-height: 48px;
  background-color: var(--color-primary);
}

.sidebar__navbar ul li a:hover {
  text-decoration: none;
  color: var(--color-white);
  line-height: 48px;
  background-color: var(--color-primary-light);
}

.sidebar__navbar ul li.active > a {
  background-color: var(--color-primary-dark);
}

.sidebar__navbar ul li ul {
  padding-left: 48px;
  font-size: var(--fontsize-small);
}

.sidebar__navbar li ul li {
  list-style-type: none;
  padding-left: 0px;
  font-weight: normal;
}

.sidebar__navbar ul li ul li a {
  text-decoration: none;
  color: var(--color-background);
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
