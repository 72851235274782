.templates-assign-screen {
  padding: 8px 16px 16px 16px;
  min-height: 380px;
  min-width: 360px;
  display: flex;
  flex-direction: column;
}

.templates-assign-screen__form {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.templates-assign-screen__form > div {
  width: 100%;
}

.templates-assign-screen__form {
  margin: 8px 0;
}
