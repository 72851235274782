.organization-screen {
  padding: 0px 0px !important;
}

.organization-dashboard {
  min-height: 210px;
  background-color: var(--color-white);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 16px;
  margin-bottom: 32px;
  border-radius: 4px;
}

.organization-screen__dashboard {
  border-top: solid 2px var(--color-background-dark);
  background-color: var(--color-white);
  padding: 32px;
}

.organization-screen__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.organization-dashboard__graph {
  border-radius: 4px;
  border: solid 1px var(--color-background-dark);
}

.organization-dashboard__graph-container {
  flex: 1;
}

.organization-dashboard__kpis {
  border-radius: 4px;
  border: solid 1px var(--color-background-dark);
  height: 160px;
  display: flex;
  padding: 16px;
  box-sizing: border-box;
}

.organization-dashboard__kpis__card {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.organization-dashboard__kpis__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 128px;
  height: 64px;
  fill: var(--color-black);
}

.organization-dashboard__inno {
  border-radius: 4px;
  border: solid 1px var(--color-background-dark);
  height: 160px;
  display: flex;
  padding: 16px;
  box-sizing: border-box;
}

.organization-dashboard__inno__card {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  fill: var(--color-inno);
}

.organization-dashboard__card {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  fill: var(--color-black);
  border-right: solid 1px var(--color-background);
}

.chart-innovations__link {
  text-decoration: none;
  color: var(--color-black);
}

.chart-innovations__link:hover {
  text-decoration: underline;
}
