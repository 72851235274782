.export-body {
  background-color: white;
  height: 100%;
  font-family: "Open Sans", "sans-serif";
  font-size: 12px;
  color: #333333;
}

.export-idea__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-top: 16px;
}

.export-idea {
  padding: 8px 8px 0px 8px;
  border: solid 1px gainsboro;
  border-radius: 4px;
}

.export-idea__image {
  height: 160px;
  background-image: url("../../assets/export-placeholder.jpg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: solid 1px gainsboro;
}

.export-idea__description {
  border-bottom: solid 1px gainsboro;
}
