.wizard-poll-ideas {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(160px, 240px));
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  box-sizing: border-box;
}

.wizard-poll-ideas__idea {
  margin-right: 8px;
  box-sizing: border-box;
  height: 100%;
}
