.users-remove-screen {
  width: 480px;
  padding: 16px;
  box-sizing: border-box;
}

.users-remove__headline {
  padding-bottom: 16px;
}

.users-remove__container {
  display: flex;
  width: 100%;
  margin-top: 16px;
}

.users-remove__buttons {
  display: flex;
  width: 100%;
}

.users-remove__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
  justify-content: flex-end;
  align-items: center;
}

.users-remove-radio {
  display: block;
  flex: 1;
}

.users-remove-radio__input {
  display: none;
}

.users-remove-radio__input + label .users-remove-radio__button {
  background-color: var(--color-white);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 2px var(--color-background);
  height: 80px;
  transition: border 150ms ease-in-out, fill 150ms ease-in-out;
}

.users-remove-radio__input:checked + label .users-remove-radio__button {
  border: solid 2px var(--color-danger);
  fill: var(--color-danger);
}

.users-remove-radio__input + label .users-remove-radio__button:hover {
  border: solid 2px var(--color-danger);
  fill: var(--color-danger);
}

.users-remove__manual {
  width: 100%;
}

.users-remove__file-upload {
  display: block;
  flex: 1;
}

.users-remove__file-upload__input {
  display: none;
}

.users-remove__file-upload__input + label .users-remove__file-upload__button {
  background-color: var(--color-white);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray-lighter);
  height: 48px;
  transition: border 150ms ease-in-out, fill 150ms ease-in-out;
}

.users-remove__file-upload__input
  + label
  .users-remove__file-upload__button:hover {
  background-color: var(--color-gray-light);
  fill: var(--color-secondary);
}

.users-remove__file-upload__input
  + label
  .users-remove__file-upload__button--error {
  fill: var(--color-error);
}
