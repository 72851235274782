.campaign-reuse-screen {
  padding: 8px 16px 16px 16px;
  min-height: 380px;
  min-width: 360px;
  display: flex;
  flex-direction: column;
}

.campaign-reuse-screen__form {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.campaign-reuse-screen__form > div {
  width: 100%;
}

.campaign-reuse-screen__form {
  margin: 8px 0;
}

.campaign-reuse-screen__preview {
  margin: 24px 0;
}

.campaign-reuse-screen__preview-placeholder {
  background-color: var(--color-gray-light);
  border-color: var(--color-gray-darker);
  height: 184px;
  border-radius: 8px;
}
