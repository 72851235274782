.user {
  font-weight: bold;
  display: flex;
  align-items: center;
  margin: 0px;
}

.user--small {
  font-weight: bold;
  font-size: var(--fontsize-small);
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.user__badge {
  margin-right: 8px;
}

.user-email {
  color: var(--color-black);
}

.user__email--invert {
  color: var(--color-white);
}

.user__icon {
  padding-left: 4px;
}
