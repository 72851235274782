.superadmin-screen {
  --filter-row-height: 64px;
  padding: 0 !important;
}
.superadmin-screen__filter-row {
  box-sizing: border-box;
  margin-top: 2px;
  padding: 0px 32px;
  height: var(--filter-row-height);
  background-color: var(--color-white);
  box-shadow: 0px 2px 4px rgba(81, 63, 85, 0.2);
}

.organizations__container {
  padding: 0px 32px 32px 32px;
}

.organizations__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin: 32px;
}

.organizasions__grid .organization {
  display: block;
}
