.ingrid-template {
  display: flex;
  min-height: 184px;
}

.ingrid-template__content {
  display: flex;
  flex-direction: column;
}

.ingrid-template__header-link {
  text-decoration: none;
  color: var(--color-black);
}

.ingrid-template__header-dropdown {
  position: absolute;
  height: 24px;
  width: 24px;
}

.ingrid-template__header-link:hover {
  text-decoration: underline;
}

.ingrid-template__header > span {
  display: block;
}

.ingrid-template__header-container {
  display: flex;
  align-items: center;
  padding: 12px;
  padding-right: 8px;
  border-radius: 4px 4px 0px 0px;
  border-bottom: solid 1px var(--color-background);
}

.ingrid-template__header {
  width: 100%;
  padding: 4px 0px;
}

.ingrid-template__header-menu {
  display: flex;
  align-items: center;
}

.ingrid-template__header-menu:hover {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}

.ingrid-template__header-name {
  margin-bottom: 2px;
  margin-right: 8px;
  font-weight: bold;
  display: flex;
}

.ingrid-template__row {
  padding: 0px 12px;
  height: 32px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-background);
}

.ingrid-template__row--bottom {
  padding: 0px 12px;
  height: 100%;
  display: flex;
  border-bottom: none;
  height: 32px;
  padding-bottom: 8px;
  padding-top: 6px;
}

.ingrid-template__category-name {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}

.ingrid-template__category-type {
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-right: 4px;
}

.ingrid-template__category-users {
  margin-left: 8px;
}

.ingrid-template__category-count {
  display: flex;
  align-items: center;
  text-align: right;
  margin-left: 12px;
}

.ingrid-template__icon-box--nocircle {
  padding-left: 2px;
  fill: var(--color-black);
}
