.templates-screen__plan-form {
  max-width: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.templates-screen__plan-form > .button {
  margin: 8px;
  min-width: 130px;
}

.templates-screen__plans-grid {
  display: grid;
  gap: 32px;
}
