.polls-screen {
  padding: 0px !important;
  border-top: solid 2px var(--color-background-dark);
}

.polls-screen__container {
  padding: 0px 32px 32px 32px;
}

.polls-screen__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-top: 16px;
}

.polls-screen .campaign {
  display: block;
}

.polls-screen__create-button {
  display: block;
  padding-top: 1px;
  margin-right: 8px;
  float: left;
}

.polls-screen__all-campaigns-header {
  display: flex;
  align-items: center;
  justify-items: flex-end;
}

.polls-screen__all-campaigns-headline {
  flex: 1;
}
