.organization-info ul {
  list-style-type: none;
  list-style-position: inside;
  padding: 0;
}

.organization-info__container {
  display: flex;
}

.organization-info__boxes {
  flex: 1;
  display: flex;
}

.organization-info__box {
  border: solid 1px var(--color-background);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  width: 96px;
  flex-direction: column;
  margin-left: 4px;
}
