.template-wizard-basic {
  max-width: 480px;
}

.template-wizard-basic__label {
  flex: 1;
}

.template-wizard-basic__section {
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
}
