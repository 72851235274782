.notification-create-screen {
  width: 480px;
  padding: 16px;
  min-height: 420px;
  display: flex;
  flex-direction: column;
}

.notification-create-screen__body {
  flex: 1;
}

.notification-create-screen__section {
  margin-bottom: 16px;
}

.notification-create-screen__label {
  display: inline-block;
  margin-bottom: 4px;
}

.notification-create-screen__footer {
}
