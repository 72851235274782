.badge {
  border-radius: 100%;
  text-align: center;

  width: 32px;
  height: 32px;
  line-height: 32px;
}

.badge--small {
  width: 21px;
  height: 21px;
  line-height: 21px;
  font-size: 9px;
}

.badge--large {
  width: 32px;
  height: 32px;
  font-size: 32px;
}

.badge__background {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--color-gray-dark);
}

.badge__background--invert {
  background: var(--color-white);
}

.badge__background--team {
  border-radius: 4px;
  background: var(--color-background-dark);
}

.badge__initials {
  color: var(--color-white);
  font-weight: bold;
}

.badge__initials--invert {
  color: var(--color-black);
}

.badge__initials--team {
  color: var(--color-black);
}

.badge--organization {
  min-width: 64px;
  height: 21px;
  font-size: 9px;
}
