.headline {
  font-size: var(--fontsize-large);
  font-weight: bold;
}

.headline--small {
  font-size: var(--fontsize-normal);
  font-weight: bold;
}

.headline--large {
  font-size: var(--fontsize-mega);
  font-weight: bold;
}

.headline--inline {
  display: inline-block;
}

.headline--center {
  text-align: center;
}
