.filter-row {
  display: flex;
  justify-items: flex-start;
  align-items: center;
  min-height: 64px;
  position: relative;
}

.filter-row__sort {
  display: flex;
  min-height: 48px;
  align-items: center;
  padding-top: 16px;
  justify-content: space-between;
}

.filter-row__search-tag button {
  background-color: var(--color-primary);
  border: none;
  margin-left: 8px;
  border-radius: 0px 4px 4px 0px;
  height: 24px;
  width: 24px;
  padding-top: 2px;
}

.filter-row__search-tag button:hover {
  background-color: var(--color-danger);
}

.filter-row__item {
  margin-right: 8px;
  margin-left: 8px;
  min-width: 160px;
}

.filter-row .select-placeholder {
  width: 160px;
}

.filter-row__item--search {
  width: 180px;
  margin-left: 0;
}

.filter-row__select {
  width: 100%;
}

.filter-row__item--campaign {
  margin-right: 0;
}

.filter-row__item-icon {
  margin-right: 6px;
  fill: var(--color-black);
}

.filter-row__item--category .react-select__control {
  max-height: 38px;
}
.filter-row__item--category .react-select__value-container {
  max-height: 36px;
  overflow-y: hidden;
}

.filter-row__label {
  display: flex;
}

.filter-row__toolbox {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
}
.filter-row__toolbox > .button {
  margin-left: 16px;
}

.ingrid-user__dropdown {
  position: relative;
  height: 36px;
  border: solid 1px var(--color-secondary);
  color: var(--color-secondary);
  border-radius: 4px;
  transition: all 150ms ease-in-out;
  box-sizing: border-box;
  margin-left: 8px;
  width: 80px;
}

.ingrid-user__dropdown:hover {
  color: var(--color-white);
  background-color: var(--color-secondary);
  border: solid 1px var(--color-secondary);
}

.ingrid-user__dropdown__text {
  position: absolute;
  text-align: center;
  width: 100%;
  line-height: 34px;
  font-size: var(--fontsize-small);
  font-weight: bold;
}

.ingrid-user__dropdown-container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
