.timeline-comment__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeline-comment__button {
  background: none;
  border: none;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  fill: var(--color-error);
}

.timeline-comment__content:hover .timeline-comment__button {
  opacity: 1;
}

.timeline-comment__button:hover {
  fill: var(--color-error-light);
}

.timeline__generic-content {
  display: flex;
  margin-top: 4px;
  padding-left: 12px;
  margin-left: 30px;
  border-left: solid 2px var(--color-gray-light);
}

.timeline-idea-created__image {
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background-size: cover;
  margin-right: 12px;
}

.timeline-idea-created__text {
  display: block;
}

.timeline__update-campaign-idea {
  fill: var(--color-gray-dark);
}

.timeline__update-campaign-idea--inno {
  display: flex;
  align-items: center;
  fill: var(--color-gray-dark);
}

.timeline__update-campaign-idea__icon {
  width: 20px;
  height: 20px;
  background-color: var(--color-inno);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  fill: var(--color-white);
  margin-right: 8px;
}

.timeline__unknown {
  display: flex;
  align-items: center;
  height: 32px;
  fill: var(--color-alert);
}
