.button {
  background-color: var(--color-secondary);
  border: 0px;
  border-radius: 4px;
  height: 44px;
  color: var(--color-white);
  padding-left: 12px;
  padding-right: 12px;
  font-size: var(--fontsize-normal);
  transition: background-color 0.1s ease-in-out;
  text-transform: uppercase;
  font-weight: bold;
}

.button--fullwidth {
  width: 100%;
}

.button--small {
  height: 36px;
  font-size: var(--fontsize-small);
}

.button--tiny {
  height: 20px;
  font-size: var(--fontsize-small);
}

.button:hover {
  background-color: var(--color-secondary-light);
  color: var(--color-white);
}

.button--icon {
  display: flex;
  align-items: center;
}

.button--danger {
  background-color: var(--color-danger);
}
.button--danger:hover {
  background-color: var(--color-danger-light);
}

.button--disabled {
  background-color: var(--color-gray-light);
}

.button--disabled:hover {
  background-color: var(--color-gray-light);
}

.button--secondary {
  background-color: transparent;
  border: solid 1px var(--color-gray-light);
  color: var(--color-secondary);
  fill: var(--color-secondary);
}

.button--secondary:hover {
  border: solid 1px var(--color-secondary-light);
  background-color: var(--color-secondary-light);
  color: var(--color-white);
  fill: var(--color-white);
}

.button--inverted {
  border-color: var(--color-white);
  color: var(--color-secondary);
  fill: var(--color-secondary);
  background-color: var(--color-white);
}

.button--inverted-secondary {
  border: solid 1px var(--color-white);
  color: var(--color-white);
  fill: var(--color-white);
  background-color: transparent;
}
