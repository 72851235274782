.ideas-screen {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  --filter-row-height: 64px;
  position: relative;
  max-height: 100%;
  min-height: 100%;
}

.ideas-screen--modal {
  flex: 1;
  min-width: 70vw;
  max-height: 90vh;
  background-color: var(--color-background);
}

.ideas-screen__advanced-filter {
  display: flex;
}

.ideas-screen__filter-row {
  margin-top: 2px;
  padding: 0px 32px;
  background-color: var(--color-white);
  height: var(--filter-row-height);
  box-sizing: border-box;
  box-shadow: 0px 2px 16px var(--color-shadow);
  z-index: 100;
}

.ideas-screen__info-toast {
  line-height: 4;
}

.ideas-screen__idea {
  /* Ideas were too short sometimes*/
  /* height: 268px; */
}

.ideas-screen__idea--placeholder {
  height: 268px;
}

.ideas-screen__content {
  flex: 1;
  overflow-x: hidden;
  padding: 0px 32px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.ideas-screen__grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(196px, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-top: 16px;
}

.ideas-screen__footer {
  background: white;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0px -2px 4px rgba(81, 63, 85, 0.1);
  z-index: 100;
}
.ideas-screen__footer--modal {
  box-shadow: 0px -2px 4px rgba(81, 63, 85, 0.1);
  background-color: var(--color-white);
  z-index: 100;
}
.ideas-screen__footer > div {
  margin-right: 16px;
  display: flex;
  align-items: center;
}
.ideas-screen__footer__make-poll-button {
  margin-left: 16px;
  min-width: 120px;
}

.ideas-screen__sort {
  display: flex;
  align-items: center;
}

.ideas-screen__sort__border {
  border-right: solid 2px var(--color-background-dark);
  margin-left: 16px;
  padding-right: 16px;
}

.ideas-screen__sort .react-select {
  min-width: 100px;
}

.ideas-screen__idea {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.ideas-screen__idea-order {
  font-weight: bold;
  width: 32px;
  text-align: center;
  border-radius: 3px 0px 3px 0px;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 32px;
  line-height: 32px;
  background-color: var(--color-white);
}

.ideas-screen__idea-order--1 {
  height: 32px;
  line-height: 32px;
  background-color: #f1d678;
}

.ideas-screen__idea-order--2 {
  height: 32px;
  line-height: 32px;
  background-color: silver;
}
.ideas-screen__idea-order--3 {
  height: 32px;
  line-height: 32px;
  background-color: burlywood;
}

.ideas-screen [data-title] {
  z-index: 1;
}
