.campaign-wizard-basic {
  max-width: 480px;
}

.campaign-wizard-basic__label {
  flex: 1;
}

.campaign-wizard-basic__error {
  margin-top: 16px;
}
