/*
---EXAMPLE---
div className="dropdown-container">
          <IconMenu width="... />
          <div className="dropdown"> - position: relative;
            <Dropdown */

.dropdown__icon {
  position: relative;
  width: 100%;
  height: 100%;
}

.dropdown__menu {
  position: absolute;
  bottom: 100%;
  left: 50%;
  border-radius: 4px;
  min-width: 160px;
  display: visible;
  border-radius: 4px;
  box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  transform: translate(-50%);
  overflow: hidden;
  z-index: 100;
}

.dropdown__menu--left {
  position: absolute;
  bottom: 100%;
  transform: translate(-100%);
}

.dropdown__menu--bottom {
  position: absolute;
  left: 0%;
  transform: translate(-100%, 95%);
}

.dropdown__button {
  width: 100%;
  border: none;
  font-size: var(--fontsize-normal);
  display: flex;
  align-items: center;
  fill: var(--color-black);
  height: 48px;
  font-weight: bold;

  background-color: var(--color-white);
  color: var(--color-black);
  border-bottom: solid 1px var(--color-background);
  padding-left: 16px;
  padding-right: 16px;
}

.dropdown__link {
  border: none;
  font-size: var(--fontsize-normal);
  display: flex;
  align-items: center;
  fill: var(--color-black);
  height: 48px;
  font-weight: bold;

  background-color: var(--color-white);
  color: var(--color-black);
  border-bottom: solid 1px var(--color-background);
  padding-left: 16px;
  padding-right: 16px;
}

.dropdown__button {
  box-sizing: border-box;
}
.dropdown__button:hover {
  font-size: var(--fontsize-normal);
  background-color: var(--color-background);
}

a:hover .dropdown__link {
  font-size: var(--fontsize-normal);
  background-color: var(--color-background);
}

.dropdown__menu a {
  cursor: default;
  text-decoration: none;
  color: var(--color-black);
}

.dropdown__button-icon {
  width: 16px;
  margin-right: 16px;
}
