.filter-row {
  display: flex;
  justify-items: flex-start;
  align-items: center;
  min-height: 64px;
}

.filter-row__item {
  margin-right: 8px;
  margin-left: 8px;
  width: 160px;
}

.filter-row .select-placeholder {
  width: 160px;
}

.filter-row__item--search {
  width: 180px;
  margin-left: 0;
}

.filter-row__select {
  width: 100%;
}

.filter-row__item--campaign {
  margin-right: 0;
}

.filter-row__item-icon {
  margin-right: 6px;
  fill: var(--color-black);
}

.filter-row__label {
  display: flex;
}

.filter-row__toolbox {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.filter-row__toolbox > div {
  margin-left: 16px;
}

.ingrid-user__dropdown {
  position: relative;
  height: 36px;
  border: solid 1px var(--color-secondary);
  color: var(--color-secondary);
  border-radius: 4px;
  transition: all 150ms ease-in-out;
  box-sizing: border-box;
  margin-left: 8px;
  width: 80px;
}

.ingrid-user__dropdown:hover {
  color: var(--color-white);
  background-color: var(--color-secondary);
  border: solid 1px var(--color-secondary);
}

.ingrid-user__dropdown__text {
  position: absolute;
  text-align: center;
  width: 100%;
  line-height: 34px;
  font-size: var(--fontsize-small);
  font-weight: bold;
}

.ingrid-user__dropdown-container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
