.idea-detail__layout {
  display: grid;
  width: 640px;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas:
    "cover cover"
    "content content"
    "backlog backlog";
  box-sizing: border-box;
}

.modal__content .idea-detail__layout {
  overflow-y: auto;
}

.idea-detail__description {
  display: block;
  padding-right: 16px;
}

.idea-detail__info {
  display: flex;
  flex-direction: column;
}

.idea-detail__info-sides {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.idea-detail__block-left {
  flex: 2;
  margin-bottom: 16px;
}

.idea-detail__block-right {
  flex: 1;
  padding: 8px;
  border: solid 1px var(--color-background-dark);
  border-radius: 4px;
  height: 100%;
}

.idea-detail__tags-panel {
  margin-bottom: 16px;
  display: flex;
}

.idea-detail__tag-headline {
  height: 32px;
  margin-right: 4px;
}

.tag__input-container {
  display: flex;
  margin-bottom: 4px;
  color: var(--color-background);
}

.idea-detail__input--tag {
  background-color: var(--color-background-dark);
  border: none;
  color: var(--color-black);
  outline: none;
  border-radius: 4px 0px 0px 4px;
  margin-left: 4px;
  padding-left: 8px;
}

.idea-detail__input--tag:focus {
  background-color: var(--color-background);
  padding-left: 8px;
}

.idea-detail__tags-list {
  display: flex;
  flex-wrap: wrap;
}

.idea-detail__tags-add {
  padding: 6px;
  background-color: var(--color-gray-light);
  border-radius: 4px;
  border: none;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  margin-right: 4px;
  outline: none;
  transition: background-color 200ms ease-in-out;
}

.idea-detail__tags-add--rotated {
  background-color: var(--color-black);
}

.idea-detail__tags-add:hover {
  background-color: var(--color-secondary);
}

.idea-detail__tags-add--rotated:hover {
  background-color: var(--color-gray-darker);
}

.idea-detail__tags-plus--normal {
  transition: transform 100ms ease-in-out;
}

.idea-detail__tags-plus--rotated {
  transform: rotate(45deg);
}

.idea-detail__tags-confirm {
  padding: 4px;
  padding-left: 2px;
  background-color: var(--color-secondary);
  border-radius: 0px 4px 4px 0px;
  border: none;
  width: 23px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.idea-detail__tags-confirm:hover {
  background-color: var(--color-secondary-light);
}

.ingrid-input--monetary-value {
  text-align: right;
  padding-right: 44px;
  margin-bottom: 4px;
  display: inline-block;
}

.monetary-value__input-container {
  position: relative;
  top: 0px;
  right: 0px;
  color: var(--color-gray-light);
}

.monetary-value__buttons {
  display: flex;
  margin-bottom: 8px;
  border-bottom: solid 1px var(--color-background);
  padding-bottom: 8px;
}
.ingrid-input--monetary-value__currency {
  position: absolute;
  top: 10px;
  right: 12px;
}

.monetary-value__currency {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.monetary-value__icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  margin-right: 8px;
}

.idea-detail__inno-edit {
  display: none;
  margin-left: 4px;
  width: 100%;
}

.idea-detail__value-edit {
  display: none;
  margin-left: 4px;
  width: 100%;
}

.idea-detail__inno-label {
  display: flex;
  width: 100%;
}

.monetary-value__currency:hover .idea-detail__inno-edit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--color-danger);
}

.monetary-value__currency:hover .idea-detail__value-edit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--color-black);
}

.idea-detail__inno-edit:hover {
  text-decoration: underline;
  cursor: pointer;
}

.idea-detail__value-edit:hover {
  text-decoration: underline;
  cursor: pointer;
}

.idea-detail__innovation-button {
  font-size: var(--fontsize-normal);
  font-style: italic;
  background: none;
  border: none;
  padding: 0px;
  margin: 0px;
  color: var(--color-black);
}

.idea-detail__innovation-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.idea-detail__inno-circle {
  background-color: var(--color-inno);
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  padding: 4px;
  border-radius: 50%;
  box-sizing: border-box;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.idea-detail__content {
  grid-area: content;
  padding: 0px 16px 16px 16px;
  background-color: var(--color-white);
}

.idea-detail__backlog {
  grid-area: backlog;
  background-color: var(--color-white);
  padding: 0px 16px 16px 16px;
  border-radius: 0px 0px 4px 4px;
}

.idea-detail__backlog-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.idea-detail__backlog-headline {
  padding: 0px;
  border-right: solid 2px var(--color-gray-lighter);
  padding-right: 8px;
  display: inline-block;
}

.idea-detail__backlog-filter {
  background: none;
  border: none;
  fill: var(--color-gray-light);
  display: flex;
  padding-top: 4px;
  cursor: pointer;
  color: var(--color-black);
}

.idea-detail__backlog-filter--selected {
  fill: var(--color-secondary);
  color: var(--color-black);
  font-weight: bold;
}

.idea-detail__backlog-filter:hover,
.idea-detail__backlog-filter--selected:hover {
  fill: var(--color-secondary-light);
  font-weight: bold;
  color: var(--color-black);
}

.idea-detail__photo {
  transition: height 300ms ease-in-out;
  height: 240px;
  width: 640px;
  grid-area: cover;
  position: relative;
  overflow: hidden;
  background-color: black;
  border-radius: 4px 4px 0px 0px;
}

.idea-detail__photo--collapsed {
  width: 640px;
  height: 640px;
  grid-area: cover;
}

.idea-detail__photo__image {
  cursor: zoom-in;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: scale(3);
  transition: transform 300ms ease-in-out, opacity 150ms ease-in-out;
}

.idea-detail__photo__image:hover {
  opacity: 0.5;
}

.idea-detail__photo__image--collapsed {
  transform: scale(1);
  background-position: center;
  cursor: zoom-out;
}

.idea-detail__photo__image--collapsed:hover {
  opacity: 1;
  color: rgba(0, 0, 0, 0);
}

.idea-detail__kebab {
  width: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  box-sizing: border-box;
}

.idea-detail__author {
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  font-size: var(--fontsize-small);
  border-bottom: solid 2px var(--color-gray-lighter);
  box-sizing: border-box;
  margin-bottom: 16px;
  margin-top: 2px;
  white-space: nowrap;
}
