.app-header {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  text-align: right;
  height: var(--header-height);
  position: relative;
  z-index: 1000;
}

.app-header__content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.app-header__logo {
  height: 100%;
  display: flex;

  background-color: var(--color-primary-dark);
  align-items: center;
  justify-content: center;
  min-width: var(--sidebar-width);

  padding-top: 8px;
}

.app-header__left {
  display: flex;
  align-items: center;
}

.app-header__right {
  display: flex;
  align-items: center;
}

.app-header__title {
  flex: 1;
  text-align: left;
  margin-right: 16px;
  margin-left: 32px;
  padding-bottom: 0px !important;
}

.app-header__select {
  margin-left: 32px;
  margin-right: 32px;
  text-align: left;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app-header__select > div {
  min-width: 200px;
}

.app-header__select > button {
  margin-left: 8px;
  min-width: 82px;
}

.app-header__select--placeholder > div {
  min-height: 32px;
}

.app-header__select select {
  background-image: url("../assets/icons/icon-arrow-down.svg");
  background-position: right 0.7em top 50%, 0 0;
  background-size: 12px;
  background-repeat: no-repeat;
}
