.timeline {
  margin-top: 16px;
  background-color: var(--color-background);
  border-radius: 4px;
  padding: 8px;
}

.timeline__event {
  margin-bottom: 16px;
}

.timeline__event__header {
  display: flex;
  align-items: center;
}

.timeline__event__badge {
  margin-right: 8px;
}

.timeline__event__user {
  display: inline-block;
  margin-right: 8px;
}

.timeline__event--comment {
  margin-top: 8px;
}
