.idea-create-screen {
  width: 480px;
  padding: 16px;
  box-sizing: border-box;
}

.idea-create-screen__section {
  margin-bottom: 16px;
}

.idea-create-screen__label {
  margin-bottom: 8px;
}

.idea-create-screen-radio {
  display: block;
  flex: 1;
}

.idea-create-screen-radio__input {
  display: none;
}

.idea-create-screen-radio__button {
  margin-bottom: 4px;
}

.idea-create-screen-radio__input + label .idea-create-screen-radio__button {
  background-color: var(--color-white);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: solid 2px var(--color-background);
  height: 48px;
  padding-left: 16px;
  transition: border 150ms ease-in-out, fill 150ms ease-in-out;
}

.idea-create-screen-radio__input:checked
  + label
  .idea-create-screen-radio__button {
  border: solid 2px var(--color-secondary);
  fill: var(--color-secondary);
}

.idea-create-screen-radio__input
  + label
  .idea-create-screen-radio__button:hover {
  border: solid 2px var(--color-secondary);
  fill: var(--color-secondary);
}
