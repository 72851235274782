.campaign-wizard-notifications {
  margin-top: 32px;
}

.campaign-wizard-notifications .ingrid-add-card {
  width: calc(100% - 48px);
  margin-left: 48px;
  margin-bottom: 16px;
  min-height: 64px;
}
