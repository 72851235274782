.app-layout {
  display: grid;
  min-height: 100vh;
  grid-template-columns: var(--sidebar-width) auto;
  grid-template-rows: var(--header-height) auto;
  grid-template-areas:
    "header header header"
    "sidebar main main"
    "sidebar main main";
  box-sizing: border-box;
}

.app-layout--simple {
  grid-template-areas: "main main main";
}

.app-layout__header {
  background-color: var(--color-white);
  grid-area: header;
}

.app-layout__header--simple {
  grid-area: header;
  background-color: var(--color-background);
  display: none;
}

.app-layout__sidebar {
  grid-area: sidebar;
  background-color: var(--color-primary);
  max-height: calc(100vh - var(--header-height));
  overflow-x: auto;
}

.app-layout__main {
  grid-area: main;
  background-color: var(--color-background);
  overflow-y: auto;
  /* [screen height] - [header height] - [content padding]  */
  height: calc(100vh - var(--header-height));
  box-sizing: border-box;
  position: relative;
  display: flex;
}

/* fullscreen for route screen wrapper */
.app-layout__main > div {
  width: 100%;
}
/* fullscreen for route screen */
.app-layout__main > div > div {
  box-sizing: border-box;
  padding: 32px 32px; /* default, can be owerwritten in screen's styles */
  width: 100%;
}

.app-layout__main--simple {
  margin: 0 auto;
}

.app-layout__chat {
  grid-area: chat;
}
