.campaign-state {
  background-repeat: no-repeat;
  background-position-y: center;
  padding-left: 16px;
  font-size: var(--fontsize-small);
  font-style: italic;
}

.campaign-state--draft {
  background-image: url("../../assets/icons/campaign-states/icon-state-draft.svg");
}

.campaign-state--running {
  background-image: url("../../assets/icons/campaign-states/icon-state-running.svg");
}

.campaign-state--finished {
  background-image: url("../../assets/icons/campaign-states/icon-state-finished.svg");
}

.campaign-state--pending-approval {
  background-image: url("../../assets/icons/campaign-states/icon-state-approval.svg");
}

.campaign-state--ready {
  background-image: url("../../assets/icons/campaign-states/icon-state-ready.svg");
}
