.inno-process-screen {
  display: flex;
  min-height: calc(100vh - var(--header-height));
  overflow-x: hidden;
}

.inno-process-container {
  padding: 8px;
}

.inno-process-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 220px));
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  box-sizing: border-box;
}

.inno-process-divider {
  background-color: var(--color-white);
  min-width: 4px;
  max-width: 4px;
  border-radius: 4px;
  margin-left: 32px;
  margin-right: 32px;
}

.inno-process-column {
  min-width: 240px;
  width: 240px;
  background-color: var(--color-background-dark);
  border-radius: 4px;
  transition: background-color 300ms ease-in-out;
}

.inno-process-column--first {
  flex: 1;
  background-color: var(--color-background-dark);
  border-radius: 4px;
  transition: background-color 300ms ease-in-out;
  min-width: 240px;
}

.inno-process-column--drag {
  min-height: 240px;
  width: 240px;
  background-color: var(--color-gray-light);
  border-radius: 4px;
}

.inno-process-column__header {
  background-color: var(--color-white);
  border-radius: 4px 4px 0px 0px;
  fill: var(--color-inno);
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: background-color 300ms ease-in-out, fill 300ms ease-in-out;
}

.inno-process-column--drag .inno-process-column__header {
  background-color: var(--color-inno);
  fill: var(--color-white);
  color: var(--color-white);
  border-radius: 4px 4px 0px 0px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
