.dropdown-button {
  --dropdown-button-height: 44px;
  --dropdown-button-width: 96px;
}

.dropdown-button--small {
  --dropdown-button-height: 34px;
  --dropdown-button-width: 80px;
}

.dropdown-button {
  position: relative;
  width: var(--dropdown-button-width);
  height: var(--dropdown-button-height);
  line-height: var(--dropdown-button-height);
  border: solid 1px var(--color-gray-light);
  color: var(--color-secondary);
  border-radius: 4px;
  transition: all 150ms ease-in-out;
}

.dropdown-button:hover,
.dropdown-button--primary {
  color: var(--color-white);
  background-color: var(--color-secondary);
  border: solid 1px var(--color-secondary);
}

.dropdown-button--primary:hover {
  background-color: var(--color-secondary-light);
  color: var(--color-white);
}

.dropdown-button--disabled,
.dropdown-button--disabled:hover {
  background-color: transparent;
  border-color: var(--color-gray-light);
  color: var(--color-gray-light);
}

.dropdown-button__text {
  position: absolute;
  text-align: center;
  font-weight: bold;
  width: var(--dropdown-button-width);
  height: var(--dropdown-button-height);
}

.dropdown-button-container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
