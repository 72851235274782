/*
<div class="react-select-container">
  <div class="react-select__control">
    <div class="react-select__value-container">...</div>
    <div class="react-select__indicators">...</div>
  </div>
  <div class="react-select__menu">
    <div class="react-select__menu-list">
      <div class="react-select__option">...</div>
    </div>
  </div>
</div>
*/

.react-select-container {
  width: 100%;
}

.react-select__control {
  background-color: var(--color-primary);
  border-color: var(--color-gray-light) !important;
}

.react-select__indicator {
  color: var(--color-gray-light) !important;
}

.react-select__indicator:hover {
  color: var(--color-black) !important;
}

.react-select__indicator-separator {
  background-color: var(--color-background) !important;
}

.react-select__single-value {
  color: var(--color-black) !important;
}

.react-select__multi-value {
  color: var(--color-black) !important;
  background-color: var(--color-background-dark) !important;
}

.react-select__multi-value__remove {
  color: var(--color-black) !important;
  background-color: var(--color-background-dark) !important;
}

.react-select__multi-value__remove:hover {
  color: var(--color-white) !important;
  background-color: var(--color-gray-light) !important;
  border-radius: 0px 4px 4px 0px;
}

.react-select__placeholder {
  color: var(--color-gray-dark) !important;
}

.select-placeholder {
  height: 38px;
  background-color: var(--color-gray-light);

  border-radius: 4px;
}

.react-select--dropup .react-select__menu {
  top: inherit !important;
  bottom: 100%;
}
