.date-time-input--dropup .react-datepicker-popper {
  /* top: initial;
  bottom: 0; */
}
.date-time-input .react-datepicker-wrapper {
  width: 100%;
}
.date-time-input .react-datepicker__input-container input {
  width: 100%;
  height: 38px;
  border-radius: 4px;
  padding-left: 8px;
  border: 1px solid var(--color-gray-light);
  background-color: var(--color-white);
  color: var(--color-black);
  font-size: var(--fontsize-normal);
  box-sizing: border-box;
}
