.template-wizard {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: solid 1px var(--color-gray-light);
}

.template-wizard__header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.template-wizard__headline {
  margin-bottom: 8px;
}

.template-wizard__header__check {
  margin-left: 8px;
}

.template-wizard__section {
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
}

.template-wizard__section--dates {
  display: flex;
}

.template-wizard__section--inline {
  display: flex;
  justify-content: left;
  align-items: center;
}

.template-wizard__note {
  margin-bottom: 8px;
}

.template-wizard__checkbox {
  margin-top: 16px;
  height: 48px;
}

.template-wizard-categories__container {
  display: flex;
  margin-top: 32px;
}
