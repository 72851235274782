.users-screen {
  --filter-row-height: 64px;
  padding: 0 !important;
  max-height: calc(100vh - var(--header-height));
  overflow: hidden;
}

.users-screen__filter-row {
  box-sizing: border-box;
  margin-top: 2px;
  padding: 0px 32px;
  height: var(--filter-row-height);
  background-color: var(--color-white);
  box-shadow: 0px 2px 4px rgba(81, 63, 85, 0.2);
}

.users-screen__list {
  box-sizing: border-box;
}

.users-screen__error {
  padding: 32px 64px;
  text-align: center;
}

.users-screen__content {
  padding: 32px 32px;
  box-sizing: border-box;
}

.users-screen__list-container {
  height: calc(100vh - var(--header-height) - var(--filter-row-height) - 104px);
}

.users-screen__header {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid var(--color-gray-light);
}

.users-screen__header-column {
  width: 64px;
  min-width: 64px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.users-screen__header-column--big {
  width: 140px;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.users-screen__header-column--first {
  margin-left: 16px;
  flex: 1;
  min-width: 180px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.users-screen__header-column--last {
  text-align: right;
  width: 64px;
  min-width: 32px;
  margin-right: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.users-screen__header-column--sort {
  cursor: pointer;
}

.users-screen__header-column--menu {
  width: 60px;
}

.users-screen .ReactVirtualized__Grid__innerScrollContainer {
  /* Force fix for dropdown cut-offs */
  overflow: visible !important;
}
