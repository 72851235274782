.create-poll-wizard__notifications {
  margin-top: 32px;
}

.notifications__timeline__container .ingrid-add-card {
  width: calc(100% - 48px);
  margin-left: 48px;
  margin-bottom: 16px;
  min-height: 64px;
}
