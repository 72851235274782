.template-plan {
  background-color: var(--color-background-dark);
  box-shadow: none;
}

.template-plan__teplates-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-auto-rows: auto;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-top: 16px;
}

.template-plan__teplates-grid > div {
  min-height: 184px;
}

.template-plan__head {
  display: flex;
  justify-content: center;
  align-items: center;
}

.template-plan__name {
  margin-top: 8px;
  margin-bottom: 8px;
  flex: 1;
}
.template-plan__name > input {
  width: auto !important;
}
.template-plan__name .button {
  margin-left: 8px;
}

/* .template-plan__meta {
  display: flex;

  justify-content: flex-end;
  align-items: center;
} */

.template-plan__toolbox {
  display: flex;
  justify-content: center;
  align-content: center;
}
.template-plan__toolbox > .button {
  margin-left: 16px;
  min-width: 152px;
}
