.idea {
  transition: transform 150ms ease-in-out;
  padding: 0px 0px 4px 0px !important;
  border-radius: 4px;
  box-sizing: border-box;
  width: 100%;
}

.idea:hover {
  box-shadow: 0px 2px 4px rgba(100, 84, 126, 0.2);
}

.idea-attribute__tags {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 8px 0px 4px 12px;
}

.idea-attribute__tag {
  background-color: var(--color-primary-lighter);
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  height: 16px;
  font-size: var(--fontsize-tiny);
  color: var(--color-white);
  margin-right: 4px;
  margin-bottom: 4px;
  white-space: nowrap;
}

.idea--highlight {
  box-shadow: 0px 4px 6px var(--color-secondary),
    0 0 0 2px var(--color-secondary) !important;
}

.idea--placeholder {
  padding: 0 !important;
}

.idea__photo {
  width: 100%;
  height: 144px;
  border-radius: 3px 3px 0px 0px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.idea__photo-detail {
  opacity: 0;
  visibility: hidden;
  background-color: var(--color-white);
  width: 0px;
  position: absolute;
  right: -50%;
  top: 150%;
  transition: opacity 0ms ease-in-out 0ms;
  z-index: 50;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 4px 8px 8px rgba(100, 84, 126, 0.4);
}

.idea__photo-detail__image {
  border-bottom: solid 1px var(--color-background);
}

.idea__photo-detail__icon {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 0;
  fill: var(--color-white);
}

.idea__photo-detail__icon:hover .idea__photo-detail {
  width: 240px;
  visibility: visible;
  opacity: 1;
  transition: opacity 0ms ease-in-out 600ms;
}

.idea__photo--placeholder {
  background-color: var(--color-gray-light);
  /* height: ; */
}

.idea__comment {
  background-image: url("../../assets/icons/color/icon-comment.svg");
  background-size: 24px 22px;
  background-repeat: no-repeat;
  width: 24px;
  height: 22px;
  position: absolute;
  bottom: 8px;
  left: 8px;
  box-sizing: border-box;
  text-align: center;
}

.idea__replicated {
  background-color: var(--color-secondary);
  height: 22px;
  min-width: 22px;
  float: left;
  position: absolute;
  bottom: 8px;
  right: 8px;
  box-sizing: border-box;
  text-align: center;
  border-radius: 4px;
  padding: 0px 4px;
}

.idea__reported {
  background-color: var(--color-danger);
  text-align: center;
  border-radius: 4px;
  width: 12px;
}

.idea__description {
  display: flex;
  padding: 12px;
  line-height: 16px;
  min-height: 16px;
}

.idea__edit-description-button {
  fill: var(--color-black);
}

.idea__edit-description-button:hover {
  fill: var(--color-secondary);
}

.idea__edit-textarea {
  width: 100%;
}

.idea__edit-textarea__butts {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}
.idea__edit-textarea__butts .button[type="submit"] {
  margin-right: 4px;
}

.idea-attributes__campaigns {
  border-top: solid 3px var(--color-background);
}

.idea-attributes__others {
  border-top: solid 3px var(--color-background);
}

.idea-attribute {
  display: flex;
  align-items: center;
  height: 32px;
  padding-left: 12px;
  padding-right: 12px;
  border-bottom: solid 1px var(--color-background);
}

.idea-attribute:last-child {
  border-bottom: solid 0px;
}

.idea-attribute__icons {
  display: flex;
  margin-left: auto;
}

.idea-attribute__icon {
  width: 12px;
  margin-left: 4px;
  fill: var(--color-gray-dark);
}

.idea-attribute--placeholder {
  background-color: var(--color-gray-light);
  border-radius: 2px;
  height: 8px;
  margin-top: 10px;
}

.idea__badge {
  padding-right: 8px;
  display: inline-block;
  width: 16px;
  height: 16px;
}

.idea__icon {
  padding-right: 8px;
  display: inline-block;
  width: 14px;
  height: 14px;
  margin: 2px;
  fill: var(--color-gray-darker);
}

.idea__selected-checkbox {
  position: relative;
  width: 28px;
  height: 28px;
  top: -1px;
  background-position: center;
  background-color: var(--color-secondary);
  background-image: url("../../assets/icons/color/icon-checkbox.svg");
  background-repeat: no-repeat;
  background-size: 16px;
  border-radius: 4px 0px 4px 0px;
}

.idea__removable-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-color: rgba(0, 0, 0, 0);
  background-image: url("../../assets/icons/color/icon-cross.svg");
  background-repeat: no-repeat;
  background-size: 32px;
  border-radius: 4px 4px 0px 0px;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.idea__removable-overlay:hover {
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 1;
}
