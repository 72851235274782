.comment-form {
  display: flex;
}

.comment-form__input {
  margin-right: 8px;
}

.comment-form__button {
  margin: 0px 0px 0px 8px;
}
