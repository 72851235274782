.text {
  font-size: var(--fontsize-normal);
}

.text--tiny {
  font-size: var(--fontsize-tiny);
}

.text--small {
  font-size: var(--fontsize-small);
}

.text--large {
  font-size: var(--fontsize-large);
}

.text--bold {
  font-weight: bold;
}

.text--nowrap {
  white-space: nowrap;
}

.text--block {
  display: inline-block;
}

.text--italic {
  font-style: italic;
}

.text--truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text--center {
  text-align: center;
  display: block;
}
