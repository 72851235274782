.notification__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.notification-card-container {
  display: flex;
}

.notification__header--left {
  display: flex;
  align-items: center;
}

.notification__header--left:hover {
  text-decoration: underline;
  cursor: pointer;
}

.notification__content {
  margin-bottom: 16px;
  padding: 12px;
  border-radius: 4px;
  background-color: var(--color-background);
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
}

.notification__card__campaign {
  display: flex;
  align-items: center;
}

.notification__footer {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

.notification-delete {
  display: flex;
  align-items: center;
  padding-left: 16px;
  fill: var(--color-gray-light);
}

.notification-delete:hover {
  display: flex;
  align-items: center;
  padding-left: 16px;
  fill: var(--color-danger);
}

.notification__header-menu {
  display: flex;
  align-items: center;
}

.notification__header-menu:hover {
  display: flex;
  align-items: center;
  background-color: var(--color-gray-lighter);
  border-radius: 50%;
}

.notification__header-dropdown {
  position: absolute;
  height: 24px;
  width: 24px;
}

.notification__team-badges {
  display: flex;
}
