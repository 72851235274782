.poll-detail-screen {
  padding: 16px 16px !important;
}

.poll-detail__idea {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.poll-detail__idea-order {
  font-weight: bold;
  width: 32px;
  text-align: center;
  border-radius: 0px 2px 0px 4px;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 32px;
  line-height: 32px;
  background-color: var(--color-white);
}

.poll-detail__idea-order--1 {
  height: 32px;
  line-height: 32px;
  background-color: #f1d678;
}

.poll-detail__idea-order--2 {
  height: 32px;
  line-height: 32px;
  background-color: silver;
}
.poll-detail__idea-order--3 {
  height: 32px;
  line-height: 32px;
  background-color: burlywood;
}

.poll-detail__user-row {
  display: flex;
  align-items: center;
  font-size: var(--fontsize-small);
  height: 48px;
  border-top: solid 1px var(--color-background);
}

.poll-detail__grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(160px, 200px));
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding: 8px 4px 16px 4px;
}
