.create-campaign-wizard__notifications {
  margin-top: 32px;
}

.create-campaign-wizard__notifications__button {
  width: calc(100% - 48px);
  background-color: var(--color-gray-lighter);
  fill: var(--color-white);
  margin-left: 48px;
  flex: 1;
  margin-bottom: 16px;
  height: 64px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out,
    fill 200ms ease-in-out;
}

.create-campaign-wizard__notifications__button:hover {
  background-color: var(--color-gray-light);
  color: var(--color-white);
}
