.loading-square {
  --loading-square-gradient: linear-gradient(
    20deg,
    var(--color-white) 30%,
    rgb(241, 241, 248) 50%,
    var(--color-white) 70%
  );
  animation: loadingSquareMovement 60s infinite linear;
  opacity: 0.7;
  background-color: var(--color-background-dark);
  background: var(--loading-square-gradient);
  border-radius: 4px;
}

.loading-square--invert {
  opacity: 0.7;
  --loading-square-gradient: linear-gradient(
    20deg,
    var(--color-gray-lighter) 20%,
    ghostwhite 50%,
    var(--color-gray-lighter) 80%
  );
}

@keyframes loadingSquareMovement {
  from {
    background-position: 0 -4000px;
  }
  to {
    background-position: 0 4000px;
  }
}
