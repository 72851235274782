.ingrid-checkbox__icon {
  display: none;
}

.ingrid-checkbox {
  display: flex;
  align-items: center;
  padding: 4px 0px;
}

.ingrid-checkbox__clickable-area {
  display: flex;
  align-items: center;
}

.ingrid-checkbox__icon + label span {
  box-sizing: border-box;
  display: inline-block;
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  margin-right: 4px;
  border: 1px solid var(--color-gray-light);
  cursor: pointer;
  background-image: none;
  border-radius: 4px;
  background-size: contain;
  background-color: var(--color-white);
}

.ingrid-checkbox__icon:checked + label span {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
  background-image: url("../../assets/icons/color/icon-checkbox.svg");
}
