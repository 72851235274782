/* mr8 =  margin-right: 8px; atd. */

.mr4 {
  margin-right: 4px;
}

.ml4 {
  margin-left: 4px;
}

.mb4 {
  margin-bottom: 4px;
}

.mt4 {
  margin-top: 4px;
}

.mr8 {
  margin-right: 8px;
}

.ml8 {
  margin-left: 8px;
}

.mb8 {
  margin-bottom: 8px;
}

.mt8 {
  margin-top: 8px;
}

.mr12 {
  margin-right: 12px;
}

.ml12 {
  margin-left: 12px;
}

.mb12 {
  margin-bottom: 12px;
}

.mt12 {
  margin-top: 12px;
}

.mr16 {
  margin-right: 16px;
}

.ml16 {
  margin-left: 16px;
}

.mb16 {
  margin-bottom: 16px;
}

.mt16 {
  margin-top: 16px;
}

.mr32 {
  margin-right: 32px;
}

.ml32 {
  margin-left: 32px;
}

.mb32 {
  margin-bottom: 32px;
}

.mt32 {
  margin-top: 32px;
}

.pr0 {
  padding-right: 0px;
}

.pl0 {
  padding-left: 0px;
}

.pb0 {
  padding-bottom: 0px;
}

.pt0 {
  padding-top: 0px;
}

.pr16 {
  padding-right: 16px;
}

.pl16 {
  padding-left: 16px;
}

.pb16 {
  padding-bottom: 16px;
}

.pt16 {
  padding-top: 16px;
}

.color-primary {
  color: var(--color-primary);
}
.color-error {
  color: var(--color-error);
}
.color-danger {
  color: var(--color-danger);
}
.color-alert {
  color: var(--color-alert);
}
.color-green {
  color: var(--color-green);
}
.color-gray {
  color: var(--color-gray-light);
}

.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-height {
  height: 100%;
}
