.notification-detail__card {
  padding: 16px;
  width: 480px;
}

.notification-detail__headline {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.notification-detail__text {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 12px;
  background-color: var(--color-background);
  margin-top: 8px;
  margin-bottom: 16px;
}

.notification-detail__campaign {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 16px;
  border-radius: 4px;
  padding: 12px;
  border: solid 1px var(--color-background);
}

.notification-detail__categories {
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 4px;
}

.notification-detail__description {
  margin-left: 22px;
}

.notification-detail__idea-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(80px, 1fr));
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
}

.notification-detail__idea {
  width: 100%;
  border-radius: 6px;
}
