.poll-dashboard {
  min-height: 210px;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;
  border-radius: 4px;
  padding: 32px;
}
