.create-campaign-wizard {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: solid 1px var(--color-gray-light);
}

.campaign-wizard__header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.campaign-wizard__headline {
  margin-bottom: 8px;
}

.campaign-wizard__header__check {
  margin-left: 8px;
}

.create-campaign-wizard__step {
}

.campaign-wizard-basic {
  /* background: lightblue; */
}

.campaign-wizard__section {
  display: flex;
  margin-bottom: 16px;
  flex-direction: column;
}

.campaign-wizard__section--dates {
  display: flex;
}

.campaign-wizard__section--inline {
  display: flex;
  justify-content: left;
  align-items: center;
}

.campaign-wizard__note {
  margin-bottom: 8px;
}

.campaign-wizard__checkbox {
  margin-top: 16px;
  height: 48px;
}

.campaign-wizard__warning {
  margin-top: 16px;
}

.campaign-wizard-categories {
}

.campaign-wizard-categories__container {
  display: flex;
  margin-top: 32px;
}

.campaign-wizard-notifications {
}

.campaign-wizard-target {
}

.campaign-wizard-summary {
}
