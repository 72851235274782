.current-poll {
  margin-top: 16px;
  margin-bottom: 32px;
}

.current-poll--empty {
  margin-top: 16px;
  margin-bottom: 32px;
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.current-poll__main-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.current-poll__header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
}

.current-poll__buttons {
  display: flex;
  width: 100%;
}

.current-poll__headline {
  padding-bottom: 0px;
}

.current-poll__main-headline {
  width: 100%;
}

.current-poll__select {
  width: 360px;
  margin-right: 8px;
}

.current-poll__button {
  width: 160px;
}

.current-poll__ideas {
  background-color: var(--color-white);
  padding: 24px;
  border-radius: 4px;
}
.current-poll__ideas-list {
  display: flex;
  padding: 8px 4px 16px 4px;
  overflow: auto;
}

.current-poll__ideas-grid__scroll {
  overflow: auto;
  display: flex;
}

.current-poll__link {
  margin-left: 16px;
  text-decoration: none;
  font-size: var(--fontsize-small);
  line-height: 21px;
}

.current-poll__idea {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-left: 16px;
  min-width: 240px;
}

.current-poll__idea-order--null {
  display: none;
}

.current-poll__idea-order {
  font-weight: bold;
  width: 32px;
  text-align: center;
  border-radius: 0px 2px 0px 4px;
  position: absolute;
  top: 0px;
  right: 0px;
  height: 32px;
  line-height: 32px;
  background-color: var(--color-white);
}

.current-poll__idea-order--1 {
  height: 32px;
  line-height: 32px;
  background-color: #f1d678;
}

.current-poll__idea-order--2 {
  height: 32px;
  line-height: 32px;
  background-color: silver;
}
.current-poll__idea-order--3 {
  height: 32px;
  line-height: 32px;
  background-color: burlywood;
}

.current-poll__notification-card {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
