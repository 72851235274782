/* Leaderboard */

.ingrid-leaderboard {
  background-repeat: no-repeat;
  background-position-y: center;
  padding-left: 16px;
  font-size: var(--fontsize-small);
  font-style: italic;
}

.ingrid-leaderboard__tab {
  display: block;
  flex: 1;
}

.ingrid-leaderboard__tab__input {
  display: none;
}

.ingrid-leaderboard__tab__input + label .ingrid-leaderboard__tab__button {
  background-color: var(--color-white);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border-bottom: solid 4px var(--color-background);
  height: 24px;
  padding-bottom: 8px;
  transition: border 150ms ease-in-out, fill 150ms ease-in-out;
  fill: var(--color-black);
}

.ingrid-leaderboard__tab__input:checked
  + label
  .ingrid-leaderboard__tab__button {
  border-bottom: solid 4px var(--color-secondary);
  fill: var(--color-secondary);
}

.ingrid-leaderboard__tab__input + label .ingrid-leaderboard__tab__button:hover {
  border-bottom: solid 4px var(--color-secondary);
  fill: var(--color-secondary);
}

/* Users */

.ingrid-leaderboard__users {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.ingrid-leaderboard__user-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--fontsize-small);
  border-bottom: solid 1px var(--color-background);
  height: 48px;
}

.ingrid-leaderboard__user-email {
  margin-left: 8px;
}

.ingrid-leaderboard__user-order {
  margin-right: 8px;
  padding: 0px 2px 0px 2px;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
  height: 16px;
  width: 16px;
  line-height: 16px;
}

.ingrid-leaderboard__user-order--1 {
  background-color: #f1d678;
}

.ingrid-leaderboard__user-order--2 {
  background-color: silver;
}
.ingrid-leaderboard__user-order--3 {
  background-color: burlywood;
}

.ingrid-leaderboard__header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
